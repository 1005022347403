import React from "react"
import {Layout, Card, Button, Divider} from "antd"
import {useOutletContext} from "react-router-dom"
import ReactJson from '@microlink/react-json-view'
import {Request} from "kaiju.core";

import {HeaderComponent} from "core/src/components/Header";


export const SyncPage = () => {
    const {breadcrumbs} = useOutletContext<{ breadcrumbs: any }>()
    const [loading, setLoading] = React.useState<boolean>(false)
    const [data, setData] = React.useState<any>({})
    const onProcess = () => {
        setLoading(true)
        setData({})
        Request("Sync.start", {}).then(({result}) => {
            setData(result)
            setLoading(false)
        })
    }

    return <>
        <Layout className={"h-100"} style={{padding: "1.5rem"}}>
            <HeaderComponent breadcrumbs={[
                ...breadcrumbs,
                {
                    title: "Синхронизация",
                }
            ]}/>
            <Card>
                <Button onClick={onProcess} loading={loading} type={"primary"} ghost> Синхронизировать</Button>
                <Divider>Результат</Divider>
                <ReactJson src={data} displayDataTypes={false} displayObjectSize={false} name={null}/>
            </Card>
        </Layout>
    </>
}
