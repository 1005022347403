import React from "react";
import {FileSyncOutlined} from "@ant-design/icons"
import {SyncPage} from "packages/src/Sync";


export default [
    {
        name: "Berezka",
        application: {
            init: () => {
            },
            menu: (items: any[], appData: any) => {
                items.push({
                    key: "sync",
                    icon: <FileSyncOutlined />,
                    label: "Синхронизация",
                    permissions: []
                })
                items.push({
                    type: 'divider',
                    permissions: []
                })


            }
        },
        routes: {
            application: (routeData: any) => {
                routeData.children.push({
                    id: "sync",
                    path: "sync",
                    element: <SyncPage/>
                })
            }
        }
    },
]